<template>
  <section class="verify-page">
    <div class="box">
      <span class="title">Racing Max</span>
      <span class="title_zh">鴻朗保險</span>
      <p class="tip mg-b-0">Please provide the policyholder mobile number for validation purpose</p>
      <p class="tip mg-t-4">請提供保單持有人手機號碼以供驗證</p>
      <div class="form-item">
        <div class="flex-col text-left">
          <span >Policyholder Mobile Number</span>
          <span >保單持有人手機號碼</span>
        </div>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          @submit.native.prevent
        >
          <el-form-item
            ref="mobile_no"
            :rules="rules.agent_mobile_no"
            prop="mobile_no"
          >
            <v-input
              v-model="formData.mobile_no"
              preappend="+852"
              key-number
              maxlength="8"
              @keyEnter="handleConfirm"
            ></v-input>
          </el-form-item>
        </el-form>
      </div>
      <v-button @click="handleConfirm">Confirm 確認</v-button>
    </div>
  </section>
</template>

<script>
import MixinRules from '@/mixins/rules'
import {verifyLogin} from '@/api/verify'
export default {
  name: 'login',
  mixins:[MixinRules],
  data(){
    return{
      formData:{
        mobile_no:''
      }
    }
  },
  computed:{
    login_params(){
      let {timestamp,send_type,type,sign,order_no} = this.$route.query||{}
      return {
        timestamp,
        send_type,
        type,
        sign,
        order_no
      }
    }
  },
  methods:{
    handleConfirm(){
      this.$refs.form.validate((valid, rules) => {
        if(valid){
          this.network().verifyLogin(this.login_params)
        }
      })
    },
    network(){
      return{
        verifyLogin:async (params) =>{
          let {data}  = await verifyLogin({
            ...params,
            ...this.formData
          })
          localStorage.setItem('token', data.token)
          this.$router.push({
            path:'/RacingMax/doc/download',
            query:{
              ...this.login_params
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .verify-page{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .box{
      padding: 30px;
      width: 395px;
      margin: 0px 20px;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
      border-radius: 25px;
      text-align: left;
      .el-button{
        background: #29385F!important;
        width: 100%;
      }
      .title{
        font-style: normal;
        font-weight: 500;
        font-size: 17.1475px;
        line-height: 150%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pwid' on;
        color: #1A1A1C;
        display: block;
        &_zh{
          font-style: normal;
          font-weight: 500;
          font-size: 27.6998px;
        }
      }
      .tip{
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin-top: 20px;
        &.mg-b-0{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
